@use '@angular/material'as mat;
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons');
@import '@angular/material/theming';
@include mat.core();

$sparte-typography: mat.define-typography-config();

$sparte-success: (50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #4caf50,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff));
$sparte-blue: (50: #f8e1e6,
  100: #183E77,
  500: #36CCE4,
  700: #36CCE4,
  900: #F48236,
  contrast: (50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff));
$sparte-primary: mat.define-palette($sparte-blue, 100);
$sparte-accent: mat.define-palette($sparte-blue, 500);
$sparte-warn: mat.define-palette(mat.$orange-palette, 900);
$sparte-theme: mat.define-light-theme((color: (primary: $sparte-primary,
      accent: $sparte-accent,
      warn: $sparte-warn,
    ),
    typography: $sparte-typography,
    density: 0));
@include mat.all-component-themes($sparte-theme);
@include mat.all-component-typographies($sparte-typography);