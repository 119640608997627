@tailwind base;
@tailwind components;
@tailwind utilities;

@import './themes/sparte.theme.scss';

@import 'ngx-toastr/toastr';

@import 'sparte.styles.scss';

// Indeterminate checkbox flowbite fix
[type='checkbox']:indeterminate {
  background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0ndHJ1ZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyBmaWxsPSdub25lJyB2aWV3Qm94PScwIDAgMTYgMTInPgogIDxwYXRoIHN0cm9rZT0nd2hpdGUnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLXdpZHRoPSczJyBkPSdNMCA2SDE2Jy8+Cjwvc3ZnPg==');
}

body,
html {
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.material-symbols {
  font-family: 'Material Symbols Outlined';
  font-variation-settings:
    'FILL'1,
    'wght'400,
    'GRAD'0,
    'opsz'24;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-variation-settings:
    'FILL'0,
    'wght'400,
    'GRAD'0,
    'opsz'24;
}

button[disabled] {
  @apply opacity-70 cursor-not-allowed;
}

// GLOBAL Width & Height classes

.loading-data {
  width: 100%;
  height: 100vh;
  text-align: center;
  color: #707070;
  text-transform: uppercase;
  font-size: 48px;
  letter-spacing: 0.1em;
  line-height: 60px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ~~~COLORS~~~

.blue {
  color: #183E77 !important;
  border-color: #183E77 !important;
}

.orange {
  color: #F48236 !important;
  border-color: #F48236 !important;
}

.green {
  color: #4CAF50 !important;
  border-color: #4CAF50 !important;
}

.grey {
  color: #707070 !important;
  border-color: #707070 !important;
}

.light-gray {
  color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}

.sparte-list-item {
  color: #707070 !important;

  .list-buttons-container {
    display: none;
    position: absolute;
    right: 16px !important;

    .red-button {
      margin-left: 8px !important;
    }
  }

  &:hover {
    background-color: #eceff1;

    .list-buttons-container {
      display: block !important;
    }
  }

  &.selected {
    background-color: #183E77 !important;
    color: #FFFFFF !important;
  }
}

// MAT-BUTTONS & ICONS

.mat-button.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
}

.green-button,
.blue-button,
.light-blue-button,
.red-button {
  background-color: white;
  box-shadow: none;
  border-radius: 40px !important;
  border: 1px solid !important;
  font-size: 20px;
  line-height: 16px !important;
  height: 48px !important;
  min-width: 48px !important;
  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover:not(.mat-button-disabled) {
    color: #FFFFFF !important;
    box-shadow: 0px 1px 10px #0000001F, 0px 4px 5px #00000024, 0px 2px 4px #00000033 !important;
  }

  .mat-button-wrapper {
    text-transform: uppercase;
    padding: initial !important;
    display: flex !important;
    height: 100%;
    align-items: center !important;
    justify-content: center !important;

    .add-item-icon {
      margin-right: 8px;
    }
  }
}

.green-button {
  color: #4caf50 !important;
  border-color: #4caf50 !important;

  &.green-button[disabled] {
    color: #707070 !important;
    border-color: #707070 !important;

    &:hover {
      background-color: inherit !important;
      color: #707070 !important;
      box-shadow: none !important;
    }
  }

  &:hover,
  &:active {
    background-color: #4caf50 !important;
  }
}

.blue-button {
  color: #183E77 !important;
  border-color: #183E77 !important;

  &:hover,
  &:active {
    background-color: #183E77 !important;
  }
}

.light-blue-button {
  color: #183E77 !important;
  border-color: #36CCE4 !important;
  background-color: #FFFFFF !important;

  &:hover,
  &:active {
    background-color: #183E77 !important;
    color: #36CCE4 !important;
    border-color: #183E77 !important;
  }
}

.red-button {
  color: #c1092b !important;
  border-color: #c1092b !important;

  &:hover:not(.mat-button-disabled),
  &:active:not(.mat-button-disabled) {
    background-color: #c1092b !important;
  }

  &:disabled {
    color: #815d64 !important;
    border-color: #815d64 !important;
  }
}

.action-button {
  padding: 16px 24px !important;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 14px;
}

.light-blue-toggle-button,
.orange-toggle-button {
  box-shadow: none;
  padding: 0 16px;
  border-radius: 40px !important;
  border: 1px solid !important;
  line-height: 16px !important;
  height: 48px !important;
  min-width: 48px !important;
  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  .mat-button-toggle-label-content {
    text-transform: uppercase !important;
    font: 500 14px / 20px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: 0.1em;
  }

  &:hover {
    box-shadow: 0px 1px 10px #0000001F, 0px 4px 5px #00000024, 0px 2px 4px #00000033 !important;
  }
}

.mat-button-toggle-checked {
  &.light-blue-toggle-button {
    background-color: #183E77 !important;
    color: #36CCE4 !important;
    border-color: #183E77 !important;
  }

  &.orange-toggle-button {
    background-color: rgb(251, 70, 5) !important;
    color: white !important;
  }
}

.orange-toggle-button {
  border-color: rgb(251, 70, 5) !important;
  color: rgb(191, 57, 57) !important;

  &:hover,
  &:active {
    background-color: rgb(251, 70, 5) !important;
    color: white !important;
  }

}

.light-blue-toggle-button {
  color: #183E77 !important;
  border-color: #36CCE4 !important;
  background-color: #FFFFFF !important;

  &:hover,
  &:active {
    background-color: #183E77 !important;
    color: #36CCE4 !important;
    border-color: #183E77 !important;
  }
}

// LISTES INVENTAIRES

.selected {
  background-color: #183E77 !important;
  color: #FFFFFF !important;
}

.perfect-virtual-scroll {
  .ps__thumb-y {
    min-height: 8px;
  }
}

// GLOBAL SCSS

*:focus {
  outline: none !important;
}

.no-padding-modal>mat-dialog-container {
  padding: 0 !important;
  box-sizing: unset !important;
  max-height: 90vh !important;
}

.no-padding-modal {
  min-width: fit-content !important;
}

//TODO: migrate forms with UpdateFormComponent and remove classes
.form-container {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  width: 100%;
  position: relative;
  display: block;

  .mat-form-field-label {
    font-size: 1.2em !important;
  }

  .mat-input-element {
    font-size: 1.2em !important;
  }

  .header-text {
    height: 100%;
    width: 100%;
    line-height: 64px;
    font-size: 120%;
    text-transform: uppercase;
    padding-left: 16px;
  }

  .form-header {
    height: 64px;
    background-color: #183E77;
    color: #FFFFFF;
    font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
    text-align: center;
    vertical-align: middle;
  }

  .form-details-field {
    width: 100%;
  }

  .full-width-field {
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
      border: none;
      background: transparent;
    }
  }
}

.form-buttons {
  padding: 24px;

  .form-delete-button {
    color: #c1092b !important;
    border: 1px solid #c1092b !important;
    border-radius: 4px;

    &:hover {
      background-color: #c1092b !important;
      color: #FFFFFF !important;
    }
  }

  .form-cancel-button {
    color: #ff9800 !important;
    border: 1px solid #ff9800 !important;
    border-radius: 4px;

    &:hover {
      background-color: #ff9800 !important;
      color: #FFFFFF !important;
    }
  }

  .form-save-button {
    color: #4caf50 !important;
    border: 1px solid #4caf50 !important;
    border-radius: 4px;

    &.form-save-button[disabled] {
      color: #707070 !important;
      border-color: #707070 !important;

      &:hover {
        background-color: inherit !important;
        color: #707070 !important;
        box-shadow: none !important;
      }
    }

    &:hover {
      background-color: #4caf50 !important;
      color: #FFFFFF !important;
    }
  }
}

.mat-subheader {
  color: #183E77;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  height: 56px;
  border-bottom: 1px solid #E2E2E2;
}

.scope-bar-container {
  width: 100% !important;
  z-index: 10 !important;

  .route-scope-search-bar {
    min-width: 300px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-label {
    margin-left: 8px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #E2E2E2;
    border-radius: 40px !important;
  }

  .mat-form-field-outline {
    background-color: #FFFFFF !important;
    border-radius: 40px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 50px 0px 0px 50px !important;
    min-width: 26px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0px 50px 50px 0px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0px 16px 0px 16px !important;
    height: 52px !important;
  }

  .mat-form-field-flex {
    height: 48px !important;
    display: inline-flex !important;
  }

  .search-prefix {
    color: #707070;
    margin: 0px 8px 0px 8px;
    height: 24px;
    width: 24px;
    vertical-align: middle !important;
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-wrapper {
    padding: 0px !important;
    height: 48px !important;
  }

  .search-input {
    min-width: 100% !important;
  }

  .mat-form-field-infix {
    width: 100% !important;
    border: 0px !important;
    padding: 16px 0 16px 0 !important;
  }

  .mat-form-field-wrapper {
    padding: 0px !important;
    width: 100%;
  }

  .mat-icon-button {
    border-radius: 50%;
    border: 1px solid #707070;
    font-size: 14px !important;
    width: 24px !important;
    height: 24px !important;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-0.89375em) scale(0.75) !important;
    width: 133.3333333333% !important;
  }

  // ngClass du Current Scope

  .active {
    background-color: #183E77;
    color: #FFFFFF !important;
    border-radius: 40px;
    padding: 4px 0px 4px 24px !important;

  }

  .route-selector-content {
    padding: 4px 0px 4px 24px !important;
    border-radius: 40px;
    border: 2px solid #183E77;
  }

  .citadel-route-close {
    padding: 0px 12px;
  }

  .current-route-span {
    font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;

    &.no-switch {
      padding-right: 24px;
    }
  }
}

.search-input {
  color: #707070 !important;
}

.drawer-scroll {
  .ps-content {
    height: 100%;
  }
}

// Search Bars

.search-bar-container {
  max-width: 400px;
  min-width: 400px;
  border: none;
  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    .mat-form-field-outline {
      box-shadow: 0px 1px 10px #0000001F, 0px 4px 5px #00000024, 0px 2px 4px #00000033;
    }

    .mat-form-field-outline {
      color: #E2E2E2 !important;
    }

    .mat-form-field-prefix {
      border-right: 1px solid #E2E2E2 !important;
    }
  }

  &:focus-within {
    .mat-form-field-prefix {
      border-right: 1px solid #183E77 !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      color: #183e77 !important;
      border-color: #183e77 !important;
    }

    .search-bar-prefix {
      color: #183E77;
    }
  }

  .search-bar-value {
    .mat-form-field-outline {
      color: #183e77 !important;
    }

    .mat-form-field-prefix {
      border-right: 1px solid #183E77 !important;
    }
  }

  &.padding-search {
    padding: 24px 0px 0px 24px;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-width: 1px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    height: 48px !important;
  }

  .mat-form-field-outline {
    background-color: #FFFFFF !important;
    border-radius: 40px !important;
    height: 48px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-label {
    margin-left: 16px;
    height: 48px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 50px 0px 0px 50px !important;
    min-width: 26px !important;
    height: 48px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0px 50px 50px 0px !important;
    height: 48px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0px 24px 0px 16px !important;
    height: 48px !important;
  }

  .asset-assets-search-bar {
    min-width: 100% !important;
    width: 100% !important;
    height: 48px;
  }

  .mat-form-field-prefix {
    height: 48px !important;
    border-right: 1px solid #E2E2E2 !important;
    margin-right: 4px !important;
    margin: auto;
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-wrapper {
    padding: 0px !important;
    margin: 0px !important;
    height: 48px;
  }

  .search-input {
    min-width: 100% !important;
    margin-left: 16px !important;
  }

  .mat-form-field-infix {
    width: 100% !important;
    border: 0px !important;
    top: 0.6em !important;
    height: 48px !important;
    padding: 0.7em 0 1em 0 !important;
  }

  .search-bar-prefix {
    color: #707070;
    margin-right: 8px;
    height: 24px;
    width: 24px;
    margin-top: 12px !important;

    &.letter-prefix {
      display: flex;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 21px;
      font-weight: 500;
      padding-left: 4px;
    }
  }

  .mat-form-field-wrapper {
    padding: 0px !important;
    width: 100%;
  }

  .mat-form-field-suffix {
    top: 1em !important;
    left: 8px;
  }

  &.picker-suffix {
    .mat-form-field-infix {
      top: 0.25em !important;
    }

    .mat-form-field-suffix {
      top: 0.75em !important;
    }
  }
}

.separators {
  @apply w-full border-t;
}

.section-titles {
  @apply font-medium tracking-widest whitespace-nowrap pr-4;
}

.autocomplete-search-container {
  width: 100%;
  border: none;
  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-width: 1px !important;
  }

  .mat-form-field-outline-start {
    border-radius: 50px 0px 0px 50px !important;
    min-width: 26px !important;
    height: 48px !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0px 50px 50px 0px !important;
    height: 48px !important;
  }

  .mat-form-field-outline-thick {
    height: 48px !important;
  }

  .mat-form-field-outline {
    background-color: #FFFFFF !important;
    border-radius: 40px !important;
    height: 48px !important;
  }

  .mat-form-field-label {
    margin-left: 16px;
    height: 48px !important;
  }

  .mat-form-field-flex {
    padding: 0px 24px 0px 16px !important;
    height: 48px !important;
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-wrapper {
    padding: 0px !important;
    margin: 0px !important;
    height: 48px;
  }

  .mat-form-field-infix {
    width: 100% !important;
    border: 0px !important;
    top: 0.6em !important;
    height: 48px !important;
    padding: 0.7em 0 1em 0 !important;
  }

  &:hover {
    .mat-form-field-outline {
      box-shadow: 0px 1px 10px #0000001F, 0px 4px 5px #00000024, 0px 2px 4px #00000033;
    }

    .mat-form-field-outline {
      color: #E2E2E2 !important;
    }

  }

  &:focus-within {

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      color: #183e77 !important;
      border-color: #183e77 !important;
    }
  }
}

.no-padding-form-field-menu {
  .mat-form-field-wrapper {
    padding: 0px !important;
    margin: 0px !important;
  }

  .mat-menu-content:not(:empty) {
    padding: 0px !important;
  }
}

.clear-filter-button {
  width: 24px !important;
  height: 24px !important;

  :hover {
    color: #183E77 !important;
  }
}

.force-scroll-content {
  .ps {
    height: 100% !important;
    width: 100% !important;
  }

  .ps-content {
    height: 100% !important;
    width: 100% !important;
  }
}

//// MULTIPLE SPECIFIC CASES

.current-object-container {
  position: relative;
  height: 100%;
  min-width: 360px;
  width: 25%;
  background-color: #FFFFFF;
  border-left: 1px solid #E2E2E2;
}

.no-article {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #E2E2E2;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 0.1em;
  line-height: 33px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

// STOCK VIRTUAL TABLE

.col-2 {
  width: 2%;
}

.col-3 {
  width: 3%;
}

.col-4 {
  width: 4%;
}

.col-5 {
  width: 5%;
}

.col-10 {
  width: 10%;
}

.col-15 {
  width: 15%;
}

.col-20 {
  width: 20%;
}

.col-25 {
  width: 25%;
}

.col-30 {
  width: 30%;
}

.col-35 {
  width: 35%;
}

.col-40 {
  width: 40%;
}

.col-90 {
  width: 90%;
}

.column-titles {
  width: 100%;
  position: relative;
  height: 60px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #707070;
  text-align: start;
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
}

.custom-panel {
  border: 1px solid #E2E2E2;
  border-radius: 0;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  box-shadow: none !important;

  &:hover {
    background-color: #eceff1 !important;
  }

  &.mat-expanded:hover {
    background-color: #FFFFFF !important;
  }

  &.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
  &.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
  &.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: none !important;
  }

  &.mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-bottom: 0 !important;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

// Check-box
.full-checkbox {
  &.mat-option {
    color: #707070;
    margin: 0 16px;
    padding: 0;
  }

  .mat-checkbox-layout {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.gray-content {
  color: #707070;
}

// Box-shadow

.ele-2dp {
  box-shadow: 0px 2px 2px #00000024, 0px 3px 1px #0000001F, 0px 1px 5px #00000033 !important;
}

.ele-hover-2dp {
  &:hover {
    box-shadow: 0px 2px 2px #00000024, 0px 3px 1px #0000001F, 0px 1px 5px #00000033 !important;
  }

  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),
  box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ele-4dp {
  box-shadow: 0px 1px 10px #0000001F, 0px 4px 5px #00000024, 0px 2px 4px #00000033 !important;
}

.ele-hover-4dp {
  &:hover {
    box-shadow: 0px 1px 10px #0000001F, 0px 4px 5px #00000024, 0px 2px 4px #00000033 !important;
  }

  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),
  box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ele-6dp {
  box-shadow: 0px 6px 10px #00000024, 0px 1px 18px #0000001F, 0px 3px 5px #00000033 !important;
}

.ele-hover-6dp {
  &:hover {
    box-shadow: 0px 6px 10px #00000024, 0px 1px 18px #0000001F, 0px 3px 5px #00000033 !important;
  }

  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),
  box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ele-8dp {
  box-shadow: 0px 12px 17px #00000024, 0px 3px 14px #0000001F, 0px 5px 5px #00000033 !important;
}

.ele-hover-8dp {
  &:hover {
    box-shadow: 0px 12px 17px #00000024, 0px 3px 14px #0000001F, 0px 5px 5px #00000033 !important;
  }

  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),
  box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ele-12dp {
  box-shadow: 0px 1px 10px #0000001F, 0px 5px 22px #0000001F, 0px 7px 8px #00000033 !important;
}

.ele-hover-12dp {
  &:hover {
    box-shadow: 0px 1px 10px #0000001F, 0px 5px 22px #0000001F, 0px 7px 8px #00000033 !important;
  }

  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),
  box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.no-shadow {
  box-shadow: none !important;
}

.no-background {
  background: none !important;
}


/* Suffix this section at the end of your styles.css for toogling between @media screen and @media print mode */
/*############################################################################################################*/
sparte-print-page {
  /* hide the printing component from @media screen */
  display: none;
}

@media print {
  /* invert the display (show/hide) properties of the main  aplication component and the printing component */

  html,
  body {
    height: unset;
    overflow: hidden;
    min-height: 550px;
    min-width: 385px;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  sparte-print-page {
    display: block;
  }

  .stock-app {
    display: none;
  }

  .no-break {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }

  .archived-order {

    .report-title {
      font-size: 14px !important;
      margin-bottom: 16px !important;
    }

    .report-section {
      display: block !important;
      font-size: 10px !important;
      margin-bottom: 16px !important;
    }

    .report-info {
      display: block !important;
      padding: 12px 0 !important;
    }

    .report-line {
      break-inside: avoid !important;
      page-break-inside: avoid !important;
      margin-bottom: 4px !important;
    }

    .report-header {
      height: 32px !important;
      break-inside: avoid !important;
      page-break-inside: avoid !important;
    }

    .articles-table-header {
      height: 24px !important;
      margin-top: 8px !important;
    }

    .articles-table-line {
      height: 20px !important;
    }

    .label {
      min-width: 350px !important;
    }

    .quantity {
      min-width: 100px !important;
    }
  }

  .ngx-toastr {
    display: none !important;
  }
}

.custom-toast {
  background-color: white !important;
  color: #183E77 !important;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iIzAwMDAwMCI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTAgMThjLTQuNDEgMC04LTMuNTktOC04czMuNTktOCA4LTggOCAzLjU5IDggOC0zLjU5IDgtOCA4em00LjU5LTEyLjQyTDEwIDE0LjE3bC0yLjU5LTIuNThMNiAxM2w0IDQgOC04eiIvPjwvc3ZnPg==") !important;

  .toast-progress {
    background-color: #183E77;
    opacity: 1;
  }
}

/*#############################################################################################################*/
//TODO: GLOBAL CSS IN THE THEMES FILE
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

:hover::-webkit-scrollbar-track {
  background: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 999px;
}

:hover::-webkit-scrollbar-thumb {
  background: #e2e2e2;
}

::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover {
  background: #999;
}

::-webkit-scrollbar-thumb:horizontal:active,
::-webkit-scrollbar-thumb:vertical:active {
  background: #777;
}


input[type='checkbox'] {
  @apply bg-white focus:ring-0 focus:ring-offset-0 rounded-sm
}

input[type="text"] {
  @apply focus:ring-sparteBlue focus:border-sparteBlue
}

input[type="number"] {
  @apply focus:ring-sparteBlue focus:border-sparteBlue
}

input[type="date"] {
  @apply focus:ring-sparteBlue focus:border-sparteBlue
}

input[type="tel"] {
  @apply focus:ring-sparteBlue focus:border-sparteBlue
}

input[type="email"] {
  @apply focus:ring-sparteBlue focus:border-sparteBlue
}