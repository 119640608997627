.sparte-input {
  @apply bg-white border border-sparteWhite-300 text-sparteWhite-600 text-base rounded-lg focus:ring-sparteBlue-500 focus:border-sparteBlue-500 block w-full p-2.5;
}

.input-label {
  @apply block mb-2 mt-4 text-base font-medium text-sparteWhite-700;
}

.square-blue-button {
  @apply text-white bg-sparteBlue hover:bg-blue-800 font-medium rounded-lg text-base px-5 py-2.5 tracking-wide shadow-md
}

.blue-input-button {
  @apply text-white bg-sparteBlue hover:bg-blue-800 font-medium rounded-lg text-base tracking-wide shadow-md
}